import { graphql, Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { GatsbyImage } from 'gatsby-plugin-image'
import debounce from 'lodash.debounce'
import get from 'lodash.get'
import React, { createRef, useEffect, useState } from 'react'
import * as Icon from 'react-feather'
import Masonry from 'react-masonry-css'
import { connect } from 'react-redux'
import Scrollspy from 'react-scrollspy'
import { Waypoint } from 'react-waypoint'
import styled, { css } from 'styled-components'
import Button from '../components/button'
import HTMLBlock, { withHtmlContent } from '../components/html-content'
import Layout from '../components/layout'
import MiniHeader from '../components/mini-header'
import Responsive, { FlexCol, FlexRow, ResponsiveImage } from '../components/responsive'
import Seo from '../components/seo'
import { LeadParagraph } from '../components/text'
import Title from '../components/titles'
import LogoSvg from '../images/eneance/logo-eneance-on-dark.svg'
import heroBackground from '../images/home/hero-background.jpg'
import RealisationsBusinessApps from '../images/home/realisations-business-apps.svg'
import RealisationsData from '../images/home/realisations-data.svg'
import RealisationsWebMobile from '../images/home/realisations-web-mobile.svg'
import { actions } from '../redux'
import colors from '../theme/colors'
import media, { breakpoints } from '../theme/media-queries'

const IndexPage = ({ data, toggleMenu, location }) => {
    const [showMiniHeader, setMiniHeaderShown] = useState(false)

    const waypointRef = createRef()

    const handleWaypointEnter = () => {
        setMiniHeaderShown(false)
    }

    const handleWaypointLeave = () => {
        setMiniHeaderShown(true)
    }

    const handleWindowScrolled = debounce(
        () => {
            const offsetTop = get(waypointRef, 'current._ref.offsetTop')
            if (window && window.scrollY > offsetTop) {
                setMiniHeaderShown(true)
            }
        },
        500,
        {
            leading: true,
        }
    )

    useEffect(() => {
        window.addEventListener('scroll', handleWindowScrolled)

        return () => {
            window.removeEventListener('scroll', handleWindowScrolled)
        }
    }, [handleWindowScrolled, showMiniHeader])

    const {
        document_title,
        document_description,
        main_title,
        solution_main_blocks,
        expertise_title,
        expertise_content,
        expertise_expertises,
        solution_title,
        solution_content,
        solution_image,
        solutions_synth,
        solution_slices,
        clients_title,
        clients_content,
        clients_logos,
        techno_title,
        techno_technos,
    } = get(data, 'prismicHome.data')

    const hero = {
        title: get(main_title, 'text'),
        tabs: solution_main_blocks.map(
            ({ solution_main_title, solution_main_link_title, solution_main_link_text, anchor }) => ({
                title: get(solution_main_title, 'text'),
                buttonTitle: solution_main_link_title,
                buttonText: solution_main_link_text,
                anchor,
            })
        ),
    }

    const expertise = {
        title: get(expertise_title, 'text'),
        lead: get(expertise_content, 'html'),
        businessDomains: expertise_expertises.map(({ expertise }) => {
            const { title, content, right_panel, logo } = expertise.document.data
            return {
                title: get(title, 'text'),
                htmlContent1: get(content, 'html'),
                htmlContent2: get(right_panel, 'html'),
                image: get(logo, 'localFile.childImageSharp.gatsbyImageData'),
            }
        }),
    }

    const solution = {
        title: get(solution_title, 'text'),
        lead: get(solution_content, 'html'),
        image: get(solution_image, 'localFile.childImageSharp.gatsbyImageData'),
        solutions: solutions_synth.map(({ icon, title, content, link_title, link_text, link_anchor }) => {
            return {
                icon,
                title: get(title, 'text'),
                content: get(content, 'html'),
                buttonTitle: link_title,
                buttonText: link_text,
                anchor: link_anchor,
            }
        }),
    }

    const solutionsSlices = solution_slices.map(({ primary, items }) => {
        const { type, title, content } = primary
        return {
            type,
            title: get(title, 'text'),
            lead: get(content, 'html'),
            cases: items.map(({ title, content, image, technos, client }) => {
                const clientLogo = get(client, 'document.data.logo.localFile.childImageSharp.gatsbyImageData')
                const clientLogoSquared = get(client, 'document.data.logo_squared.localFile.childImageSharp.gatsbyImageData')
                return {
                    title: get(title, 'text'),
                    content: get(content, 'html'),
                    tags: (technos || '').split(',').map((techno) => techno.trim()),
                    image: get(image, 'localFile.childImageSharp.gatsbyImageData'),
                    clientStamp: clientLogoSquared || clientLogo,
                }
            }),
        }
    })

    const solutions = [
        {
            id: 'solutions-web-mobile',
            Component: SolutionsMobile,
            IconComponent: RealisationsWebMobile,
            defaultCols: 3,
            content: solutionsSlices.find((slice) => slice.type === 'WEB_&_MOBILE'),
        },
        {
            id: 'solutions-data',
            Component: SolutionsData,
            IconComponent: RealisationsData,
            defaultCols: 3,
            content: solutionsSlices.find((slice) => slice.type === 'DATA'),
        },
        {
            id: 'solutions-business-apps',
            Component: SolutionsBusinessApps,
            IconComponent: RealisationsBusinessApps,
            defaultCols: 3,
            content: solutionsSlices.find((slice) => slice.type === 'BUSINESS_APPS'),
        },
    ]

    const clients = {
        title: get(clients_title, 'text'),
        content: get(clients_content, 'html'),
        logos: clients_logos.map(({ client }) => {
            const { logo, url, title } = client.document.data
            return {
                title: get(title, 'text'),
                url: get(url, 'url'),
                image: get(logo, 'localFile.childImageSharp.gatsbyImageData'),
            }
        }),
    }

    const technologies = {
        title: get(techno_title, 'text'),
        technos: techno_technos.map(({ techno }) => {
            const { logo, url, title } = techno.document.data
            return {
                title: get(title, 'text'),
                url: get(url, 'url'),
                image: get(logo, 'localFile.childImageSharp.gatsbyImageData'),
            }
        }),
    }

    const { navigation_items } = get(data, 'prismicMainNavigation.data')

    return (
        <Layout>
            <Seo title={get(document_title, 'text')} description={document_description} />
            <MiniHeaderHomePage forHomePage={true} active={showMiniHeader} menuOpen={true} />
            <Hero>
                <Responsive>
                    <Header>
                        <Logo />
                        <BurgerMenu size={32} onClick={() => toggleMenu(true)} />
                        <NavigationItems>
                            {navigation_items.map(({ title, href }, index) => {
                                return (
                                    <NavigationItem key={index}>
                                        <AnchorLink to={href}>
                                            <Title>{title}</Title>
                                        </AnchorLink>
                                    </NavigationItem>
                                )
                            })}
                        </NavigationItems>
                    </Header>
                    <Waypoint ref={waypointRef} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
                </Responsive>

                <HeroTitleContainer>
                    <HeroTitle as="h1" size="hero" over="dark" withDot>
                        {hero.title}
                    </HeroTitle>
                </HeroTitleContainer>

                <TabsContainer>
                    <Responsive>
                        <FlexRow justify="flex-end" align="flex-end" collapseTablet>
                            {hero.tabs.map((tab, index) => {
                                const { title, anchor, buttonTitle, buttonText } = tab
                                return (
                                    <FlexCol flexBasis="28%" flexGrow={0} key={index}>
                                        <Tab to={anchor}>
                                            <TabTitle>{title}</TabTitle>
                                            <Button icon="ArrowRight" title={buttonTitle}>
                                                {buttonText}
                                            </Button>
                                        </Tab>
                                    </FlexCol>
                                )
                            })}
                        </FlexRow>
                    </Responsive>
                </TabsContainer>
            </Hero>

            <Expertises id="expertises">
                <Responsive>
                    <Title as="h2" size="hero" withDot>
                        {expertise.title}
                    </Title>
                    <FlexRow collapseTablet>
                        <FlexCol flexBasis="80%" flexGrow={0}>
                            <HTMLLeadParagraph as="div">{expertise.lead}</HTMLLeadParagraph>
                        </FlexCol>
                    </FlexRow>
                </Responsive>
                <BusinessDomains>
                    <FlexRow collapseTablet>
                        {expertise.businessDomains.map((businessDomain, index) => (
                            <BusinessDomain key={index}>
                                <GatsbyImage image={businessDomain.image} alt="" />
                                <BusinessDomainTitle as="h3" size="large">
                                    {businessDomain.title}
                                </BusinessDomainTitle>
                                <HTMLBlock>{businessDomain.htmlContent1}</HTMLBlock>
                            </BusinessDomain>
                        ))}
                    </FlexRow>
                </BusinessDomains>
            </Expertises>

            <div id="solutions">
                <Solutions>
                    <Responsive>
                        <SolutionsArtwork>
                            <GatsbyImage image={solution.image} alt="" />
                        </SolutionsArtwork>
                        <Title as="h2" size="hero" over="light" withDot>
                            {solution.title}
                        </Title>
                        <FlexRow collapseTablet>
                            <FlexCol flexBasis="66%" flexGrow={0}>
                                <HTMLLeadParagraph as="div">{solution.lead}</HTMLLeadParagraph>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow collapseTablet align="stretch">
                            {solution.solutions.map(({ icon, title, content, anchor, buttonTitle, buttonText }, index) => {
                                const SolutionIcon = Icon[icon] || Icon.X
                                return (
                                    <SolutionCol as="article" key={index}>
                                        <div>
                                            <SolutionTitle>
                                                <SolutionIcon />
                                                <Title as="h3" size="large" over="light">
                                                    {title}
                                                </Title>
                                            </SolutionTitle>
                                            <HTMLBlock>{content}</HTMLBlock>
                                        </div>
                                        <Link to={anchor}>
                                            <Button icon="ArrowRight" hoverColor={colors.darkBlue} title={buttonTitle}>
                                                {buttonText}
                                            </Button>
                                        </Link>
                                    </SolutionCol>
                                )
                            })}
                        </FlexRow>
                    </Responsive>
                </Solutions>

                <Technologies>
                    <Responsive>
                        <Title as="h2" size="large" textAlign="center" withDot>
                            {technologies.title}
                        </Title>
                        <TechnologiesLogos
                            breakpointCols={{
                                default: 5,
                                [breakpoints.large]: 5,
                                [breakpoints.tabletPortrait]: 5,
                                [breakpoints.small]: 3,
                            }}
                            columnClassName="column">
                            {technologies.technos.map(({ title, url, image }, index) => (
                                <OutboundLink href={url} target="_blank" rel="noopener noreferrer" key={index} title={title}>
                                    <HoverImage borderRadius="0.5rem">
                                        <GatsbyImage image={image} alt={title} />
                                    </HoverImage>
                                </OutboundLink>
                            ))}
                        </TechnologiesLogos>
                    </Responsive>
                </Technologies>
            </div>

            <div id="realisations">
                <Responsive>
                    <Title as="h2" size="hero" withDot>
                        Réalisations
                    </Title>
                </Responsive>

                {solutions.map((solutions, index) => {
                    if (!solutions.content) {
                        return null
                    }
                    const { Component, IconComponent, id, content, defaultCols } = solutions
                    return (
                        <Component id={id} key={index}>
                            <Responsive>
                                <Title as="h3" size="large" bold withDot>
                                    {content.title}
                                </Title>
                                <FlexRow align="stretch" collapseTablet>
                                    <FlexCol flex={8}>
                                        <HTMLBlock>{content.lead}</HTMLBlock>
                                    </FlexCol>
                                    <FlexCol flex={4}>
                                        <BackgroundIcon>
                                            <IconComponent />
                                        </BackgroundIcon>
                                    </FlexCol>
                                </FlexRow>
                                <SolutionCases
                                    breakpointCols={{
                                        default: defaultCols,
                                        [breakpoints.tabletLandscape - 1]: 2,
                                        [breakpoints.tabletPortrait - 1]: 1,
                                    }}
                                    columnClassName="column">
                                    {content.cases.map(({ image, title, content, tags, clientStamp }, index) => {
                                        return (
                                            <SolutionCase key={index}>
                                                <ResponsiveImage borderRadius="0.5rem 0.5rem 0 0">
                                                    <GatsbyImage image={image} alt="" />
                                                </ResponsiveImage>
                                                <SolutionCaseContent>
                                                    <Title as="h3" size="medium">
                                                        {title}
                                                    </Title>
                                                    <HTMLBlock>{content}</HTMLBlock>
                                                    {tags.map((tag, index) => (
                                                        <Tag key={index}>{tag}</Tag>
                                                    ))}
                                                </SolutionCaseContent>
                                                {clientStamp && (
                                                    <ClientStamp>
                                                        <GatsbyImage image={clientStamp} alt="" />
                                                    </ClientStamp>
                                                )}
                                            </SolutionCase>
                                        )
                                    })}
                                </SolutionCases>
                            </Responsive>
                        </Component>
                    )
                })}
            </div>

            <Clients id="clients">
                <Responsive>
                    <Title as="h2" size="large" textAlign="center" withDot>
                        {clients.title}
                    </Title>
                    <ClientsLogos
                        breakpointCols={{
                            default: 5,
                            [breakpoints.large]: 5,
                            [breakpoints.tabletPortrait]: 4,
                            [breakpoints.small]: 3,
                        }}
                        columnClassName="column">
                        {clients.logos.map(({ title, url, image }, index) => (
                            <OutboundLink href={url} title={title} target="_blank" rel="noopener noreferrer" key={index}>
                                <HoverImage borderRadius="0.5rem">
                                    <GatsbyImage image={image} alt={title || ''} />
                                </HoverImage>
                            </OutboundLink>
                        ))}
                    </ClientsLogos>
                </Responsive>
            </Clients>
        </Layout>
    )
}

export const query = graphql`
    query IndexQuery {
        prismicMainNavigation {
            data {
                home_href
                navigation_items {
                    title
                    href
                }
            }
        }
        prismicHome {
            data {
                document_title {
                    text
                }
                document_description
                main_title {
                    text
                }
                solution_main_blocks {
                    solution_main_title {
                        text
                    }
                    solution_main_link_title
                    solution_main_link_text
                    anchor
                }
                expertise_title {
                    text
                }
                expertise_content {
                    html
                }
                expertise_expertises {
                    expertise {
                        document {
                            ... on PrismicExpertise {
                                data {
                                    title {
                                        text
                                    }
                                    content {
                                        html
                                    }
                                    right_panel {
                                        html
                                    }
                                    logo {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED, height: 150, placeholder: TRACED_SVG)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                solution_title {
                    text
                }
                solution_content {
                    html
                }
                solution_image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: TRACED_SVG)
                        }
                    }
                }
                solutions_synth {
                    icon
                    title {
                        text
                    }
                    content {
                        html
                    }
                    link_title
                    link_text
                    link_anchor
                }
                solution_slices {
                    ... on PrismicHomeSolutionSlicesSolutionBlock {
                        primary {
                            type
                            title {
                                text
                            }
                            content {
                                html
                            }
                        }
                        items {
                            title {
                                text
                            }
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED, width: 768, height: 432, placeholder: TRACED_SVG)
                                    }
                                }
                            }
                            content {
                                html
                            }
                            technos
                            client {
                                document {
                                    ... on PrismicClient {
                                        id
                                        data {
                                            logo {
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData(
                                                            layout: CONSTRAINED
                                                            aspectRatio: 1
                                                            transformOptions: { cropFocus: CENTER }
                                                        )
                                                    }
                                                }
                                            }
                                            logo_squared {
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                clients_title {
                    text
                }
                clients_logos {
                    client {
                        document {
                            ... on PrismicClient {
                                id
                                data {
                                    title {
                                        text
                                    }
                                    url {
                                        url
                                    }
                                    logo {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: TRACED_SVG)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                clients_content {
                    html
                }
                techno_title {
                    text
                }
                techno_technos {
                    techno {
                        document {
                            ... on PrismicTechno {
                                data {
                                    logo {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: TRACED_SVG)
                                            }
                                        }
                                    }
                                    url {
                                        url
                                    }
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const Header = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.375rem;
    padding: 1rem 0;
`

const BurgerMenu = styled(Icon.Menu)`
    cursor: pointer;
    ${media.desktop`
        display: none;
    `}
`

const MiniHeaderHomePage = styled(MiniHeader)`
    top: 0;
    opacity: 1;
    transition: all 0.2s ease-in-out;

    ${(props) =>
        !props.active &&
        css`
            top: -4rem;
            opacity: 0;
        `}
`

const Logo = styled(LogoSvg)`
    flex-shrink: 0;
    width: 150px;
    height: 154px;
    ${media.tablet`
        width: 100px;
        height: 102px;
    `}
`
const NavigationItems = styled(Scrollspy)`
    flex: 1;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${media.laptop`
        display: none;
    `}
`
const NavigationItem = styled.li`
    display: inline-block;
    position: relative;
    margin-left: 1.5rem;
    padding-bottom: 4px;
    overflow: hidden;
    & :after {
        content: '';
        transition: all 0.2s ease;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: rebeccapurple;
        transform: translateX(-100%);
        opacity: 0;
    }

    &.active,
    & :hover {
        & :after {
            background-color: ${colors.vividRed};
            transform: translateX(100%);
            width: 50%;
            opacity: 1;
        }
    }
`

const Hero = styled.section`
    color: white;
    background-color: ${colors.darkBlue};
    position: relative;
    background-image: url(${heroBackground});
    background-size: cover;
    background-position: center;
    border-bottom: solid 10px ${colors.darkBlue80};
    ${media.atLeastTablet`
        min-height: 40rem;
        height: 96vh;
        max-height: 45rem;
    `}
`
const HeroTitleContainer = styled(Responsive)`
    ${media.atLeastTablet`
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    `}
`
const HeroTitle = styled(Title)`
    ${media.atLeastLaptop`
        max-width: 85%;
    `}
`
const TabsContainer = styled.div`
    ${media.atLeastTablet`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    `}
`
const Tab = styled(Link)`
    background: rgba(53, 78, 189, 0.3);
    backdrop-filter: blur(10px);
    padding: 0.5rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.3125rem;
    & > * {
        margin: 0.5rem;
    }

    ${media.phone`
        flex-direction: column;
        align-items: flex-start;
    `}

    ${media.tablet`
        margin: 1rem 0;
    `}
    
    ${media.atLeastTablet`
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;
        padding: 1rem;
        position: relative;
        top: 5rem;
        transition: all 200ms ease-in-out;
        &:hover,
        &:active {
            top: 0.5rem;
        }
    `}
`
const TabTitle = styled(Title)`
    ${media.atLeastTablet`
        display: table-cell;
        min-height: 7rem;
        font-size: 1.375rem;
        line-height: 1.875rem;
    `}
`

const Section = styled.section`
    padding: 4rem 0;
    min-height: 30vh;
`

const Expertises = styled(Section)``

const Solutions = styled(Section)`
    color: aliceblue;
    min-height: 100vh;
    background-image: linear-gradient(170deg, #E0373C 0%, #FF474A 87%);
    ${media.atLeastTablet`
        padding: 8.75rem 0 5rem;
    `}
    & ${Responsive} {
        position: relative;
    }
`
const SolutionCol = styled(FlexCol)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const SolutionTitle = styled.header`
    display: flex;
    align-items: baseline;
    & > svg {
        margin-right: 0.5rem;
    }
`

const SolutionsSlice = styled(Section)`
    overflow: hidden;
    ${FlexCol} {
        position: relative;
    }
`

const BackgroundIcon = styled.span`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(-20%) translateX(-50%);
    z-index: 0;
    ${media.tablet`
        display: none;
    `}
    > svg {
        width: 200px;
        height: 200px;
    }
`

const SolutionsMobile = styled(SolutionsSlice)`
    padding-top: 2rem;
    ${BackgroundIcon} {
        color: ${colors.beige50};
    }
`

const SolutionsData = styled(SolutionsSlice)`
    background-image: linear-gradient(180deg, white 0%, ${colors.beige50} 100%);
    ${BackgroundIcon} {
        color: ${colors.beige80};
    }
`

const SolutionsBusinessApps = styled(SolutionsSlice)`
    background-image: linear-gradient(180deg, ${colors.beige50} 0%, ${colors.beige80} 100%);
    ${BackgroundIcon} {
        color: ${colors.beige};
    }
`

const SolutionsArtwork = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-60%);
    width: 30rem;
    ${media.tablet`
        width: 33vw;
        height: auto;
        max-width: 200px;
        right: 2rem
        transform: translateY(-50%);
    `}
`

const masonryGutter = '2rem'
const SolutionCases = styled(Masonry)`
    padding: 2rem 0;
    display: flex;
    margin-left: -${masonryGutter};
    width: auto;
    .column {
        padding-left: ${masonryGutter};
        background-clip: padding-box;
    }
`

const SolutionCase = styled.article`
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    background-color: ${colors.white80};
    ${SolutionsMobile} & {
        background-color: ${colors.beige50};
    }
    font-size: 80%;
    ${Title} {
        font-size: 1rem;
    }
    position: relative;
`

const SolutionCaseContent = styled.div`
    padding: 0 0.5rem 0.5rem 1rem;
    p,
    ul,
    ol {
        line-height: 1.25rem;
    }
`

const Tag = styled.span`
    display: inline-block;
    border-radius: 0.5rem;
    background-color: ${colors.beige};
    padding: 0.2rem 0.4rem;
    margin: 0 0.4rem 0.2rem 0;
    text-transform: uppercase;
    font-size: 90%;
    white-space: nowrap;
    ${SolutionsMobile} & {
        background-color: ${colors.white};
    }
`

const stampSize = '70px'
const ClientStamp = styled.div`
    font-size: 0;
    border-radius: 50%;
    width: ${stampSize};
    height: ${stampSize};
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translate(-10px, -20px) rotate(-3deg);
    box-shadow: 0 0 40px -20px rgba(33, 41, 77, 0.8);
`

const BusinessDomains = styled(Responsive)`
    counter-reset: business-domain-number;
    padding: 4rem 0 6rem;
`

const BusinessDomain = styled(FlexCol)`
    text-align: center;
    align-self: stretch;
    border-bottom: solid 0rem red;
    border-radius: 1rem;
    box-shadow: 0 0 30px -20px rgb(33 41 77 / 80%);
    padding: 5rem 2rem;
    transform: scale(0.9);
`
const BusinessDomainTitle = styled(Title)`
    display: block;
    & :before {
        counter-increment: business-domain-number;
        content: '#' counter(business-domain-number) ' ';
        color: ${colors.vividRed};
    }
`

const Clients = styled(Section)``

const ClientsLogos = styled(Masonry)`
    padding: 2rem 0;
    display: flex;
    margin-left: -${masonryGutter};
    .column {
        padding-left: ${masonryGutter};
        background-clip: padding-box;
    }
    & ${ResponsiveImage} {
        margin-bottom: 2rem;
    }
`

const Technologies = styled(Section)``

const TechnologiesLogos = styled(Masonry)`
    padding: 2rem 0;
    display: flex;
    margin-left: -${masonryGutter};
    .column {
        padding-left: ${masonryGutter};
        background-clip: padding-box;
    }
    & ${ResponsiveImage} {
        margin-bottom: 2rem;
    }
`

const HoverImage = styled(ResponsiveImage)`
    margin: 0.5rem;
    transition: all 0.2s ease-in-out;
    outline: 1px solid transparent;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform-style: preserve-3d;

    &:hover {
        transform: scale3d(1.05, 1.05, 1.05);
        box-shadow: 0 0 40px -20px rgba(33, 41, 77, 0.8);
    }
    &:active {
        transform: scale3d(1, 1, 1);
        box-shadow: 0 0 0 0 ${colors.darkBlue80};
    }
`

const HTMLLeadParagraph = withHtmlContent(LeadParagraph)

const mapStateToProps = (state) => {
    return {
        menuOpen: state.ui.menuOpen,
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleMenu: (b) => dispatch(actions.ui.toggleMenu(b)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
